import {
    ethers
} from "ethers";


import store from "@/store";

import useCreateDom from "../hooks/useCreateDOM";
import * as role_Contract from '@/wallet/address/Nft.roleAddress';
import * as arm_Contract from '@/wallet/address/Nft.armsAddress';
import * as gem_Contract from '@/wallet/address/Nft.gemAddress';
// import * as market_Contract from '@/wallet/address/Nft.gemAddress';

import * as logicRoleContract from '@/wallet/address/LogicRole.logicRoleAddress.json'
import * as logicArmContract from '@/wallet/address/LogicRole.logicRoleAddress.json'
import * as logicGemContract from '@/wallet/address/LogicRole.logicRoleAddress.json'
import * as logicMarketContract from '@/wallet/address/LogicMarket.logicMarketAddress.json'
import * as LogicTransferContract from '@/wallet/address/LogicTransferFrom.logicTransferFromAddress.json'

console.log(ethers.utils.toUtf8CodePoints('中国'));
const role_ContractAddress = role_Contract.address
const arm_ContractAddress1 = arm_Contract.address;
const gem_ContractAddress2 = gem_Contract.address;


const Role_logicAddress=logicRoleContract.address;
const Arm_logicAddress=logicArmContract.address;
const Gem_logicAddress=logicGemContract.address;
const market_logicAddress=logicMarketContract.address;
const LogicTransferAddress=LogicTransferContract.address;

//市场NFT授权情况
// let is_market_role=false;
// let is_market_arm=false;
// let is_market_gem=false;

const Abi =  role_Contract.abi;

export async function contact(){
  
    if (typeof window.ethereum !== 'undefined') {
     //store.commit("UpdateIsWalletConnect",false)
        let currentAccount = null;

        console.log('MetaMask 已安装!');
       
        await window.ethereum.enable();
       
        


        let rs = await window.ethereum.request({
            method: 'eth_accounts'
        })

        console.log('rs', rs);
        console.log('isConnected', window.ethereum.isConnected());

        let res2 = await window.ethereum.request({
            method: 'eth_requestAccounts'
        });

        console.log('res2', res2);
        console.log(
            window.ethereum.isMetaMask
        );


        window.ethereum.on('accountsChanged', (accounts) => {

            if (accounts.length === 0) {

                console.log('Please connect to MetaMask.');
            } else if (accounts[0] !== currentAccount) {


                console.log('accountsChanged', accounts);

            }
        });

        window.ethereum.on('chainChanged', (chainId) => {
            console.log('chainChanged', ethers.BigNumber.from(chainId).toNumber());
        });

        window.ethereum.on('connect', (connectInfo) => {
            console.log('connectInfo', connectInfo);
        });

        window.ethereum.on('disconnect', (error) => {
            console.log('disconnect', error);
        });
       
        // store.commit("UpdateIsWalletConnect",true)
      
      }else{
        // store.commit("UpdateIsWalletConnect",false)
        return false
    }
}
export async function role_setApprovalForAll(){
       
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        
        let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
        let tokenApprovetxid = await myContract.setApprovalForAll(Role_logicAddress, true);
        
        if(tokenApprovetxid){
            let approveRes = await tokenApprovetxid.wait();
            store.commit("UpdateIsRoleNftAuth",true)
            return approveRes;
        }else{
          return false
        }
        console.log('approveRes',approveRes);
       // 
}

export async function role_isApprovedForAll() {

        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner();
        let address = await signer.getAddress();
        console.log("1.检查是否授权所有NFT1")
        let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
        let isApproved = await myContract.isApprovedForAll(address,Role_logicAddress);
        console.log("2.检查是否授权所有NFT",isApproved)
       
        console.log('allowance',isApproved);
        if(isApproved){
            store.commit("UpdateIsRoleNftAuth",true)
        }
        // store.commit("UpdateIsTokenAuth",true)
        return isApproved;
}

export async function arm_setApprovalForAll() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
  let tokenApprovetxid = await myContract.setApprovalForAll(Arm_logicAddress, true);
  
  if(tokenApprovetxid){
      let approveRes = await tokenApprovetxid.wait();
      store.commit("UpdateIsArmNftAuth",true)
      return approveRes;
  }else{
    return false
  }
  console.log('approveRes',approveRes);
 // 
}

export async function arm_isApprovedForAll() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let address = await signer.getAddress();
  console.log("1.检查是否授权所有NFT1")
  let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
  let isApproved = await myContract.isApprovedForAll(address,Arm_logicAddress);
  console.log("2.检查是否授权所有NFT",isApproved)
 
  console.log('allowance',isApproved);
  if(isApproved){
      store.commit("UpdateIsArmNftAuth",true)
  }
  // store.commit("UpdateIsTokenAuth",true)

  return isApproved;
  

}

export async function gem_setApprovalForAll() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  
  let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
  let tokenApprovetxid = await myContract.setApprovalForAll(Gem_logicAddress, true);
  
  if(tokenApprovetxid){
      let approveRes = await tokenApprovetxid.wait();
      store.commit("UpdateIsGemNftAuth",true)
      return approveRes;
  }else{
    return false
  }
  console.log('approveRes',approveRes);
 // 
}

export async function gem_isApprovedForAll() {
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner();
  let address = await signer.getAddress();
  console.log("1.检查是否授权所有NFT1")
  let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
  console.log("2.检查是否授权所有NFT",myContract)
 
  let isApproved = await myContract.isApprovedForAll(address,Gem_logicAddress);
  console.log("3.检查是否授权所有NFT",isApproved)
 
  console.log('allowance',isApproved);
  if(isApproved){
      store.commit("UpdateIsGemNftAuth",true)
  }
  // store.commit("UpdateIsTokenAuth",true)

  return isApproved;
  

}

export async function market_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(market_logicAddress, true);
   
    // let myContract_gem =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    // let tokenApprovetxid_gem = await myContract_gem.setApprovalForAll(market_logicAddress, true);
   
    // let myContract_arm =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    // let tokenApprovetxid_arm = await myContract_arm.setApprovalForAll(market_logicAddress, true);
   
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
        // let approveRes1 = await tokenApprovetxid.wait();
        // let approveRes2 = await tokenApprovetxid.wait();
        store.commit("UpdateIsMarketNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function market_isApprovedForAll() {
    console.log("判断市场合约是否",)
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,market_logicAddress);
   
    // let myContract1 =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    // let isApproved1 = await myContract1.isApprovedForAll(address,market_logicAddress);
   
    // let myContract2 =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    // let isApproved2 = await myContract2.isApprovedForAll(address,market_logicAddress);
   
    if(isApproved){
        store.commit("UpdateIsMarketNftAuth",true)
    }
    // store.commit("UpdateIsTokenAuth",true)
  
    return isApproved;
    
  
  }


  export async function marketArm_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(market_logicAddress, true);
   
    
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
       
        store.commit("UpdateIsMarketArmNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function marketArm_isApprovedForAll() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,market_logicAddress);
   
  
    if(isApproved){
        store.commit("UpdateIsMarketArmNftAuth",true)
    }
   
    return isApproved;
    
  
  }


  export async function marketGem_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(market_logicAddress, true);
   
    
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
       
        store.commit("UpdateIsMarketGemNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function marketGem_isApprovedForAll() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,market_logicAddress);
   
  
    if(isApproved){
        store.commit("UpdateIsMarketGemNftAuth",true)
    }
   
    return isApproved;
    
  
  }


  //转让角色授权nft
   export async function LogicTransfer_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(LogicTransferAddress, true);
   
    
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
       
        store.commit("UpdateIsLogicTransferNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function LogicTransfer_isApprovedForAll() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(role_ContractAddress, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,LogicTransferAddress);
   
  
    if(isApproved){
        store.commit("UpdateIsLogicTransferNftAuth",true)
    }
   
    return isApproved;
    
  
  }


  //转让武器授权
  export async function armTransfer_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(LogicTransferAddress, true);
   
    
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
       
        store.commit("UpdateIsArmTransferNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function armTransfer_isApprovedForAll() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(arm_ContractAddress1, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,LogicTransferAddress);
   
  
    if(isApproved){
        store.commit("UpdateIsArmTransferNftAuth",true)
    }
   
    return isApproved;
    
  
  }

  
  //转让宝石授权
  export async function gemTransfer_setApprovalForAll() {
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    let tokenApprovetxid = await myContract.setApprovalForAll(LogicTransferAddress, true);
   
    
    if(tokenApprovetxid){
        let approveRes = await tokenApprovetxid.wait();
       
        store.commit("UpdateIsGemTransferNftAuth",true)
        return approveRes;
    }else{
       return false
    }
    console.log('approveRes',approveRes);
   // 
}
  
export async function gemTransfer_isApprovedForAll() {
    
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let address = await signer.getAddress();
   
    let myContract =await new ethers.Contract(gem_ContractAddress2, Abi, signer);
    let isApproved = await myContract.isApprovedForAll(address,LogicTransferAddress);
   
  
    if(isApproved){
        store.commit("UpdateIsGemTransferNftAuth",true)
    }
   
    return isApproved;
    
  
  }